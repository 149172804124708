import "./TimePicker.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { v4 as uuidv4 } from "uuid"

import { useOnClickOutside } from "../../../utils/hook"

const TimePicker = ({ value, onChange, gray, disabled }) => {
  const { isEuropeanFormat } = useSelector(state => state.app)

  const hoursLimit = isEuropeanFormat ? 23 : 12
  const firstHour = isEuropeanFormat ? 0 : 1
  const hours = []
  for (let i = firstHour; i <= hoursLimit; i++) {
    let newValue = i
    if (newValue < 10) {
      newValue = `0${newValue}`
    }
    hours.push(newValue)
  }

  const minutes = []
  for (let i = 0; i <= 59; i++) {
    let newValue = i
    if (newValue < 10) {
      newValue = `0${newValue}`
    }
    minutes.push(newValue)
  }

  const meridiems = ["AM", "PM"]

  const timePickerClass = classNames("time-picker-container", {
    "time-picker-gray": gray,
  })

  const [pickerOpen, setPickerOpen] = useState(false)
  const [selectedHour, setSelectedHour] = useState("")
  const [selectedMinute, setSelectedMinute] = useState("")
  const [selectedMeridiem, setSelectedMeridiem] = useState(meridiems[0])

  useEffect(() => {
    const splitValue = value.split(":")
    let newHour = Number(splitValue[0])
    if (splitValue[0]) {
      setSelectedMeridiem(newHour < 12 ? meridiems[0] : meridiems[1])
    }

    if (!isEuropeanFormat) {
      if (newHour % 12 === 0) {
        newHour = 12
      } else if (newHour > 12) {
        newHour -= 12
      }
    }
    setSelectedHour(!splitValue[0] ? splitValue[0] : newHour)
    setSelectedMinute(splitValue[1])
  }, [value])

  const selectHour = hour => {
    setSelectedHour(hour)
    let newMinutes = selectedMinute
    if (!selectedMinute) {
      newMinutes = "00"
      setSelectedMinute(newMinutes)
    }

    const hoursToSubmit =
      !!hour && selectedMeridiem === meridiems[1] && !isEuropeanFormat ? Number(hour) + 12 : hour
    onChange(`${hoursToSubmit}:${newMinutes}`)
  }

  const onChangeHour = e => {
    const { value: hour } = e.target
    if (
      !hour ||
      (hour >= 0 &&
        ((isEuropeanFormat && hour <= 23 && hour.length < 3) ||
          (!isEuropeanFormat && hour <= 12 && hour.length < 3)))
    ) {
      if (hour === "12" && selectedMeridiem === meridiems[0] && !isEuropeanFormat) {
        selectHour(0)
      } else {
        selectHour(hour)
      }
    }
  }

  const selectMinute = minute => {
    setSelectedMinute(minute)
    let newHours = selectedHour
    if (!selectedHour) {
      newHours = isEuropeanFormat ? "00" : "12"
      setSelectedHour(newHours)
    }

    const hoursToSubmit =
      selectedMeridiem === meridiems[1] && !isEuropeanFormat ? Number(newHours) + 12 : newHours
    onChange(`${hoursToSubmit}:${minute}`)
  }

  const onChangeMinute = e => {
    const { value: minute } = e.target
    if (!minute || (minute >= 0 && minute <= 59 && minute.length < 3)) {
      selectMinute(minute)
    }
  }

  const selectMeridiem = meridiem => {
    setSelectedMeridiem(meridiem)

    const hoursToSubmit =
      !!selectedHour && meridiem === meridiems[1] && !isEuropeanFormat
        ? Number(selectedHour) + 12
        : selectedHour

    onChange(`${hoursToSubmit}:${selectedMinute}`)
  }

  const timePickerRef = useRef(null)

  const togglePicker = () => {
    if (!disabled) setPickerOpen(!pickerOpen)
  }

  useOnClickOutside(timePickerRef, () => setPickerOpen(false))

  return (
    <div className={timePickerClass} onClick={togglePicker} ref={timePickerRef}>
      <span className="time-picker-text">
        <input
          className="time-picker-numbers"
          type="number"
          value={selectedHour}
          onChange={onChangeHour}
          placeholder={isEuropeanFormat ? "00" : "12"}
          min={isEuropeanFormat ? 0 : 1}
          max={isEuropeanFormat ? 23 : 12}
        />
        <span>:</span>
        <input
          className="time-picker-numbers"
          type="number"
          value={selectedMinute}
          onChange={onChangeMinute}
          placeholder="00"
          min={0}
          max={59}
        />
        {!isEuropeanFormat && <span>{selectedMeridiem}</span>}
      </span>
      {pickerOpen && (
        <div className="time-picker-wrapper">
          <div className="time-picker-side">
            {hours.map(hour => (
              <div
                key={uuidv4()}
                className="time-picker-select"
                onClick={() => {
                  selectHour(hour)
                }}
              >
                {hour}
              </div>
            ))}
          </div>
          <div className="time-picker-side">
            {minutes.map(minute => (
              <div
                key={uuidv4()}
                className="time-picker-select"
                onClick={() => {
                  selectMinute(minute)
                }}
              >
                {minute}
              </div>
            ))}
          </div>
          {!isEuropeanFormat && (
            <div className="time-picker-side">
              {meridiems.map(meridiem => (
                <div
                  key={uuidv4()}
                  className="time-picker-select"
                  onClick={() => {
                    selectMeridiem(meridiem)
                  }}
                >
                  {meridiem}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

TimePicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  gray: PropTypes.bool,
  disabled: PropTypes.bool,
}

TimePicker.defaultProps = { value: "", onChange: () => {}, gray: false, disabled: false }

export default TimePicker
